@import "~/styles/mixins.scss";
.wrapper {
	.messageContainer {
		color: $black30;
		padding: 25px 0;

		.errorCode {
			letter-spacing: -10px;
			font-size: 160px;
			font-weight: 700;
		}

		.message {
			margin-top: 40px;
			font-size: 24px;
			line-height: 28px;

			.highlight {
				font-weight: 700;
				margin-right: 3px;
			}

			.useSearch {
				margin-left: 3px;
			}
		}
	}

	.image {
		max-width: 600px;
		margin: auto;

		.img {
			object-fit: cover;
			width: 100%;
		}
	}
}
