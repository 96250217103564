@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 90px;
	color: $black30;

	.mainContent {
		.messageContainer {
			display: flex;
			justify-content: space-between;

			.highlight {
				font-size: 43px;
				font-weight: 700;
			}

			.info {
				display: inline-block;
				margin-top: 10px;
				font-size: 36px;
			}
		}

		.errorContainer {
			text-transform: uppercase;
			font-weight: 700;
			z-index: 1;

			.error {
				font-size: 26px;
			}

			.errorCode {
				font-size: 210px;
				line-height: 143px;
			}

			.errorInfo {
				margin-top: 20px;
				font-size: 18px;
				text-align: right;
			}
		}

		.image {
			max-width: 600px;
			position: relative;
			top: -40px;

			@include small-desktop {
				top: -100px;
			}

			@include desktop {
				max-width: 900px;
				margin: auto;
				top: -200px;
			}

			.img {
				object-fit: cover;
				width: 100%;
			}
		}
	}

	.backToMainPage {
		font-size: 20px;
		position: relative;
		top: -40px;

		.useSearch {
			margin-left: 3px;
		}
	}
}
